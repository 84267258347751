<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay variant="white" :show="showLoading" spinner-variant="primary" blur="0" opacity=".75" rounded="sm">
      <!-- <filters :filtros="fields" :is-add.sync="isAdd" ref="filters"> </filters> -->
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col md="8" class="d-flex flex-column flex-lg-row">
              <b-form-group label="Vista" label-for="view" class="mr-2 w-100">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="showView"
                  label="text"
                  input-id="view"
                  :reduce="(showView) => showView.value"
                  placeholder="Vista"
                  v-model="view_id"
                  @input="changeView(view_id)"
                  class="select-obra"
                >
                </v-select>
              </b-form-group>
              <b-form-group label="Proyecto" label-for="project" class="w-100">
                <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="proyectos"
                  label="code"
                  input-id="project"
                  :reduce="(proyectos) => proyectos.id"
                  placeholder="Proyecto"
                  v-model="project_id"
                  class="select-obra"
                  @input="getConsultas()"
                  
                >
                  <template v-slot:selected-option="option"> {{ option.code }} - {{ option.description }} </template>
                  <template slot="option" slot-scope="option"> {{ option.code }} - {{ option.description }} </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="4" class="d-flex justify-content-center justify-lg-content-end align-items-center">
              <b-button class="mb-1 mb-md-0" variant="primary" @click="addConsulta()">
                <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Agregar </span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <b-card v-if="view_id == 1">
        <b-tabs fill>
          <!-- Render Tabs, supply a unique `key` to each tab -->
          <b-tab
            v-for="(item, index) in records"
            :key="'dyn-tab-' + (index + 1)"
            :title="item.name"
            :lazy="index + 1 != 1"
            @click="changeTab(item)"
          >
            <b-card no-body>
              <b-table
                class="position-relative"
                :hover="true"
                :items="restricciones"
                responsive
                :fields="visibleFields2"
                show-empty
                empty-text="No existen"
                @sort-changed="sortChanged2"
                no-border-collapse
                ref="selectableTable"
              >
                <template #cell(date_identified)="data">
                  <span>{{ validDate(data.item.date_identified) }}</span>
                </template>
                <template #cell(date_required)="data">
                  <span>{{ validDate(data.item.date_required) }} </span>
                </template>
                <template #cell(date_commited)="data">
                  <span>{{ validDate(data.item.date_commited) }}</span>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <b-col sm="3">
                    <b-form-group
                      label-cols="4"
                      label-cols-md="4"
                      label-size="md"
                      label="Entradas"
                      label-for="input-md"
                    >
                      <b-form-select
                        v-model="showEntrie2"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="entries2"
                        @change="changeSizePage2"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col sm="3" class="mt-75" style="font-size: 12px">
                    <span> {{ totalElements2 }} Registros en total</span>
                  </b-col>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination
                      v-model="currentPage2"
                      :total-rows="totalElements2"
                      :per-page="showEntrie2"
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                      @change="cambioPagina2"
                      pills
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="15" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="15" />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-tab>

          <!-- Render this if no tabs -->
          <template #empty>
            <div class="text-center text-muted">No hay datos que presentar</div>
          </template>
        </b-tabs>
      </b-card>
      <b-card v-else no-body>
        <b-table
          class="position-relative"
          :hover="true"
          :items="records"
          responsive
          :fields="visibleFields"
          show-empty
          empty-text="No existen"
          no-border-collapse
          @sort-changed="sortChanged"
          ref="selectableTable"
        >
          <template #cell(actions)="data">
            <b-button
              size="sm"
              class=""
              @click.prevent="edit(data.item)"
              v-b-tooltip.noninteractive.hover.left="'Editar'"
              variant="flat-success"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>
            <b-button
              size="sm"
              class=""
              v-b-tooltip.noninteractive.hover.left="'Eliminar'"
              @click="deleteAction(data.item)"
              variant="flat-danger"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
            <b-button
              size="sm"
              class=""
              @click.prevent="open(data.item)"
              v-b-tooltip.noninteractive.hover.left="'Ver'"
              variant="flat-success"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group label-cols="4" label-cols-md="4" label-size="md" label="Entradas" label-for="input-md">
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3" class="mt-75" style="font-size: 12px">
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="15" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="15" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        id="modal-consult"
        ref="modal-consult"
        centered
        :title="isEdit ? 'Editar Consulta' : 'Agregar Consulta'"
        ok-only
        hide-footer
        no-close-on-backdrop
        @hidden="resetFormConsult()"
        size="lg"
        >
        <validation-observer #default="{ invalid }" ref="refFormObserver">
          <b-row class="">
            <b-col md="12" class="d-flex">
              <validation-provider #default="{ errors }" name="items.project_id" rules="requeridoE" class="mr-1 w-100">
                <b-form-group label="Proyecto" label-for="project" class="">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proyectos"
                    label="code"
                    input-id="project"
                    :reduce="(proyectos) => proyectos.id"
                    placeholder="Proyecto"
                    v-model="items.project_id"
                    class="select-obra"
                    @input="selectProject(items.project_id)"  
                    disabled                 
                  >
                    <template v-slot:selected-option="option"> {{ option.code }} - {{ option.description }} </template>
                    <template slot="option" slot-scope="option">
                      {{ option.code }} - {{ option.description }}
                    </template>
                  </v-select>
                  <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" class="d-flex">
              <validation-provider #default="{ errors }" name="name" rules="" class="mr-1 w-100">
                <b-form-group label="Nombre de Alerta" label-for="name" class="w-100">
                  <b-form-input v-model="name" placeholder="Nombre" />
                  <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="12" class="d-flex">
              <validation-provider #default="{ errors }" name="items.owner_id" rules="requeridoE" class="mr-1 w-100">
                <b-form-group label="Dueños" label-for="owners" class="mr-2 w-100">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="optionsWithAll"
                    label="name"
                    input-id="owners"
                    :reduce="(duenos) => duenos.id"
                    placeholder="Dueños"
                    v-model="items.owner_id"
                    @input="checkOptionAll"
                    :taggable="true"
                    @option:selecting="removeSelectedDueno"
                    multiple
                    class="fullInput"
                  >
                  <template v-slot:selected-option="option">
                    {{ option.name }}{{ option.id === 'all' ? '' : ' - ' + (option.area ? option.area.description : '') }}
                  </template>
                  <template slot="option" slot-scope="option">
                    {{ option.name }}{{ option.id === 'all' ? '' : ' - ' + (option.area ? option.area.description : '') }}
                  </template>
                  </v-select>
                  <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                    errors[0]
                  }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col sm="12" class="">
              <b-form-group label="Filtros" class="mr-2 w-100">
                <b-row class="mx-0">   
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.status_similar" rules="" class="mr-1">
                      <b-form-group label="Estado" label-for="status_similar" class="mr-2 w-100">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="estadosNuevos"
                          label="text"
                          input-id="status_similar"
                          :reduce="(estadosNuevos) => estadosNuevos.value"
                          placeholder="Todos"
                          v-model="items.status_similar"
                          class="select-obra"
                        >
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>               
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.status_restriction" rules="" class="mr-1">
                      <b-form-group label="Condicion" label-for="status_restriction" class="mr-2 w-100">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="estadosRestriccion"
                          label="text"
                          input-id="status_restriction"
                          :reduce="(estadosRestriccion) => estadosRestriccion.value"
                          placeholder="Condicion"
                          v-model="items.status_restriction"
                          class="select-obra"
                        >
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>                 
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.status" rules="" class="mr-1">
                      <b-form-group label="Caducidad" label-for="status" class="mr-2 w-100">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="estados"
                          label="text"
                          input-id="status"
                          :reduce="(estados) => estados.value"
                          placeholder="Caducidad"
                          v-model="items.status"
                          class="select-obra"
                          @input="changeStatus(items.status)"
                        >
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.time" rules="" class="mr-1">
                      <b-form-group label="Tiempo (días)" label-for="time" class="mr-2 w-100">
                        <b-form-input
                          :disabled="items.status != 'proximos-a-vencer'"
                          v-model="items.time"
                          id="time"
                          placeholder="Tiempo"
                        />
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.priority" rules="" class="mr-1">
                      <b-form-group label="Prioridad" label-for="priority" class="mr-2 w-100">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="prioridades"
                          label="text"
                          input-id="priority"
                          :reduce="(prioridades) => prioridades.value"
                          placeholder="Prioridad"
                          v-model="items.priority"
                          class="select-obra"
                        >
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.category_id" rules="requeridoE" class="mr-1">
                      <b-form-group label="Categoria" label-for="category" class="mr-2 w-100">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="optionsWithAllCategorias"
                          label="description"
                          input-id="category"
                          :reduce="(categorias) => categorias.id"
                          placeholder="Prioridad"
                          v-model="items.category_id"
                          class="fullInput"
                          multiple
                          @input="checkOptionAllCategorias"
                          :taggable="true"
                          @option:selecting="removeSelectedCategoria"
                        >
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.speciality_id" rules="requeridoE" class="mr-1">
                      <b-form-group label="Especialidad" label-for="speciality" class="mr-2 w-100">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="optionsWithAllEspecialidades"
                          label="code"
                          input-id="speciality"
                          :reduce="(especialidades) => especialidades.id"
                          placeholder="Prioridad"
                          v-model="items.speciality_id"
                          class="fullInput"
                          multiple
                          @input="checkOptionAllEspecialidades"
                          :taggable="true"
                          @option:selecting="removeSelectedEspecialidad"
                        >
                          <template v-slot:selected-option="option">
                            {{ option.code }}{{ option.id === 'all' ? '' : ' - ' + option.description }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.code }}{{ option.id === 'all' ? '' : ' - ' + option.description }}
                          </template>
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.location_1_id" rules="" class="mr-1">
                      <b-form-group label="Localización 1" label-for="location_1_id">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="local1"
                          label="code"
                          input-id="location_1_id"
                          :reduce="(local1) => local1.id"
                          placeholder="Localización 1"
                          v-model="items.location_1_id"
                          class="select-obra"
                          @input="changeLocal(items.location_1_id, 1)"
                        >
                          <template v-slot:selected-option="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.location_2_id" rules="" class="mr-1">
                      <b-form-group label="Localización 2" label-for="location_2_id">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="local2"
                          label="code"
                          input-id="location_2_id"
                          :reduce="(local2) => local2.id"
                          placeholder="Localización 2"
                          v-model="items.location_2_id"
                          class="select-obra"
                          @input="changeLocal(items.location_2_id, 2)"
                        >
                          <template v-slot:selected-option="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.location_3_id" rules="" class="mr-1">
                      <b-form-group label="Localización 3" label-for="location_3_id">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="local3"
                          label="code"
                          input-id="location_3_id"
                          :reduce="(local3) => local3.id"
                          placeholder="Localización 3"
                          v-model="items.location_3_id"
                          @input="changeLocal(items.location_3_id, 3)"
                          class="select-obra"
                        >
                          <template v-slot:selected-option="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.location_4_id" rules="" class="mr-1">
                      <b-form-group label="Localización 4" label-for="location_4_id">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="local4"
                          label="code"
                          input-id="location_4_id"
                          :reduce="(local4) => local4.id"
                          placeholder="Localización 4"
                          v-model="items.location_4_id"
                          class="select-obra"
                        >
                          <template v-slot:selected-option="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                          <template slot="option" slot-scope="option">
                            {{ option.code }} - {{ option.description }}
                          </template>
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="3" class="pl-0">
                    <validation-provider #default="{ errors }" name="items.type" rules="" class="mr-1">
                      <b-form-group label="Tipo" label-for="type" class="mr-2 w-100">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="tipos"
                          label="text"
                          input-id="type"
                          :reduce="(tipos) => tipos.value"
                          placeholder="Todos"
                          v-model="items.type"
                          class="select-obra"
                        >
                        </v-select>
                        <small class="text-danger" :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }">{{
                          errors[0]
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>   
                  <b-col md="3" class="d-flex flex-column">
                    <validation-provider #default="{ errors }" name="critic_route" rules="requeridoE">
                      <b-form-group label-for="critic_route" class="h-100">
                        <div class="d-flex w-50 align-items-center mt-1 ml-1">
                          <span class="mr-2">¿Ruta Crítica?</span>
                          <b-form-checkbox v-model="items.critic_route" name="critic_route">                       
                          </b-form-checkbox>                       
                        </div>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-row>
          <b-col sm="12" class="d-flex justify-content-center">
            <b-button class="mr-2 mb-1 mb-md-0" variant="success" @click.prevent="saveConsult()">
              <span class="text-nowrap"> Guardar </span>
            </b-button>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal size="xl" v-model="modalOpen" @close="closeModal" centered  hide-footer>
        <template v-slot:default>
          <viewQuery :datos="dataExcel" />
        </template>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from 'vue'
import filters from './filters.vue'
import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import OwnerService from '@/services/OwnerService'
import ProjectsService from '@/services/ProjectsService'
import LocalizationService from '@/services/LocalizationService'
import SpecialityService from '@/services/SpecialityService'
import CategoryService from '@/services/CategoryService'
import QueryService from '@/services/QueryService'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import viewQuery from './viewQuery.vue'
import moment from 'moment'
const APIURL = process.env.APIURLFILE
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      modalOpen: false,
      dataExcel: [],
      name: '',
      status: '',
      statusFilter: '',
      required,
      show: false,
      apiurl: APIURL,
      showLoading: false,
      fields: [
        { key: 'name', label: 'Nombre', sortable: false, visible: true },
        { key: 'actions', label: 'Acciones', visible: true }
      ],
      fields_restric: [      
        { key: 'description', label: 'Descripción', sortable: false, visible: true },
        { key: 'category.code', label: 'Categoria', sortable: false, visible: true },      
        { key: 'priority', label: 'Prioridad', sortable: false, visible: true },
        { key: 'status', label: 'Estado', sortable: false, visible: true },
        { key: 'owner.name', label: 'Dueño', sortable: false, visible: true },        
        { key: 'date_identified', label: 'F. Identificada', sortable: false, visible: true },
        { key: 'date_required', label: 'F. Requerida', sortable: false, visible: true },
        { key: 'date_commited', label: 'F. Comprometida', sortable: false, visible: true }        
      ],
      dueno_id: null,
      duenos: [],
      estados: [
        {
          text: 'Ninguna',
          value: 'ninguna'
        },
        // {
        //   text: 'Asignadas',
        //   value: 'asignadas'
        // },
        {
          text: 'Próximos a vencer',
          value: 'proximos-a-vencer'
        },
        {
          text: 'Vencidos',
          value: 'vencidos'
        }
      ],
      prioridades: [
        {
          text: 'Todos',
          value: 'todas'
        },
        {
          text: 'Alta',
          value: 'alta'
        },
        {
          text: 'Media',
          value: 'media'
        },
        {
          text: 'Baja',
          value: 'baja'
        }
      ],
      estadosNuevos: [
        {
          text: 'Todos',
          value: 'Todos'
        },
        {
          text: 'Abierto',
          value: 'Abierto'
        },
        {
          text: 'Cerrado',
          value: 'Cerrado'
        }        
      ],
      tipos: [
        {
          text: 'Todos',
          value: 'Todos'
        },
        {
          text: 'Campo',
          value: 'campo'
        },
        {
          text: 'Critico',
          value: 'critica'
        }       
      ],
      estadosRestriccion: [
        {
          text: 'Todos',
          value: 'todas'
        },
        {
          text: 'Rechazado',
          value: 'Rechazado'
        },
        {
          text: 'Oficializado',
          value: 'Oficializado'
        },
        {
          text: 'Liberado por Planner',
          value: 'LiberadoPorPlanner'
        },
        {
          text: 'Liberado Por Dueño',
          value: 'LiberadoPorDueño'
        },   
      ],
      view_id: 0,
      showView: [
        {
          text: 'Consultas',
          value: 0
        },
        {
          text: 'Restricciones',
          value: 1
        }
      ],
      tabs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      categorias: [],
      restricciones: [],
      especialidades: [],
      isEdit: false,
      indexItem: null,
      local1: [],
      local2: [],
      local3: [],
      local4: [],
      project_id: JSON.parse(localStorage.getItem('project_id')),
      name: '',
      id_consult: null,
      id_query_restric: null,
      items: {
        project_id: JSON.parse(localStorage.getItem('project_id')),
        owner_id: null,
        status: null,
        priority: 'todas',
        status_restriction:'todas',
        type: 'Todos',
        critic_route: false,
        status_similar:'Todos',
        time: '',
        category_id: 'all',
        speciality_id: 'all',
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null
      },
      records: [],
      records_temp: [],
      proyectos: [],
      arrayFilters: [],
      currentPage: 1,
      entries: [10, 20, 50, 100],
      showEntrie: 10,
      totalElements: 0,
      currentPage2: 1,
      entries2: [10, 20, 50, 100],
      showEntrie2: 10,
      totalElements2: 0,
      id: 0,
      sort: 'id',
      order: 'desc',
      sort2: 'id',
      order2: 'desc',
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  components: {
    vSelect,
    flatPickr,
    filters,
    AppCollapse,
    AppCollapseItem,
    AppTimeline,
    ValidationProvider,
    ValidationObserver,
    AppTimelineItem,
    viewQuery
  },
  computed: {
    visibleFields() {
      return this.fields.filter((field) => field.visible)
    },
    visibleFields2() {
      return this.fields_restric.filter((field) => field.visible)
    },
    optionsWithAll() {
      // Añadir la opción "Todos" al principio del array
      const optionsWithAll = [{ id: 'all', name: 'Todos' }, ...this.duenos];
      return optionsWithAll;
    },
    optionsWithAllCategorias() {
      // Añadir la opción "Todos" al principio del array
      const optionsWithAllCategorias = [{ id: 'all', description: 'Todos' }, ...this.categorias];
      return optionsWithAllCategorias;
    },
    optionsWithAllEspecialidades() {
      // Añadir la opción "Todos" al principio del array
      const optionsWithAllEspecialidades = [{ id: 'all', code: 'Todos' }, ...this.especialidades];
      return optionsWithAllEspecialidades;
    }
  },
  mounted() {
   
    // this.filter()
    this.getSelect()
    this.getConsultas()
    this.getSelectModal(this.project_id)
    // this.getData()
  },
  methods: {
   
    closeModal() {
      this.modalOpen = false;
    },
    changeView(id) {
      console.log({id})
      if (id == 1) {
        if (this.records.length > 0) {
          console.log(this.records)
          this.changeTab(this.records[0])
        }
      }
    },
    changeStatus(item) {
      if (item != 'proximos-a-vencer') {
        this.items.time = ''
      }
    },
    checkOptionAll() {
      /*const allOption = this.optionsWithAll.find((option) => option.id === 'all');
      if (allOption && this.items.owner_id.includes(allOption.id)) {
        this.items.owner_id = this.duenos.map((dueno) => dueno.id);
      } else if (allOption) {
        const index = this.items.owner_id.indexOf(allOption.id);
        if (index !== -1) {
          this.items.owner_id.splice(index, 1);
        }
      }
      console.log(this.items.owner_id)*/

      // Impedir agregar más tags si la opción "Todos" está presente
      if (this.items.owner_id.includes('all')) {
          console.log("INCLUYE")
          this.items.owner_id = ['all'];
        }

        console.log("this.items.owner_id", this.items.owner_id)

    },
    checkOptionAllCategorias() {

      // Impedir agregar más tags si la opción "Todos" está presente
      if (this.items.category_id.includes('all')) {
          console.log("INCLUYE")
          this.items.category_id = ['all'];
        }

        console.log("this.items.category_id", this.items.category_id)

    },
    checkOptionAllEspecialidades() {

      // Impedir agregar más tags si la opción "Todos" está presente
      if (this.items.speciality_id.includes('all')) {
          console.log("INCLUYE")
          this.items.speciality_id = ['all'];
        }

        console.log("this.items.speciality_id", this.items.speciality_id)

    },
    removeSelectedDueno(dueno) {
      console.log("dueno", dueno)
      if(this.items.owner_id){
        const index = this.items.owner_id.findIndex((u) => u == dueno.id);
        if (index !== -1) {
          this.items.owner_id.splice(index, 1);
        }

        // Verificar si la opción actual es "Todos"
        const isAllOption = dueno.id === 'all';

        // Si la opción actual es "Todos", eliminar todas las otras opciones
        if (isAllOption) {
          this.items.owner_id = [dueno.id];
        }

        // Eliminar Tag TODOS
        if (this.items.owner_id.includes('all') && isAllOption) {
          console.log("Delete tag TODOS")
          this.items.owner_id = null;
        }

      }
    },
    removeSelectedCategoria(categoria) {
      console.log("categoria", categoria)
      if(this.items.category_id){
        const index = this.items.category_id.findIndex((u) => u == categoria.id);
        if (index !== -1) {
          this.items.category_id.splice(index, 1);
        }

        // Verificar si la opción actual es "Todos"
        const isAllOption = categoria.id === 'all';

        // Si la opción actual es "Todos", eliminar todas las otras opciones
        if (isAllOption) {
          this.items.category_id = [categoria.id];
        }

        // Eliminar Tag TODOS
        if (this.items.category_id.includes('all') && isAllOption) {
          console.log("Delete tag TODOS")
          this.items.category_id = null;
        }

      }
    },
    removeSelectedEspecialidad(especialidad) {
      console.log("especialidad", especialidad)
      if(this.items.speciality_id){
        const index = this.items.speciality_id.findIndex((u) => u == especialidad.id);
        if (index !== -1) {
          this.items.speciality_id.splice(index, 1);
        }

        // Verificar si la opción actual es "Todos"
        const isAllOption = especialidad.id === 'all';

        // Si la opción actual es "Todos", eliminar todas las otras opciones
        if (isAllOption) {
          this.items.speciality_id = [especialidad.id];
        }

        // Eliminar Tag TODOS
        if (this.items.speciality_id.includes('all') && isAllOption) {
          console.log("Delete tag TODOS")
          this.items.speciality_id = null;
        }

      }
    },
    async changeTab(item) {
      this.restricciones = []
      this.id_query_restric = item.id
      this.currentPage2 = 1
      this.showEntrie2 = 10
      this.totalElements2 = 0
      this.getData()
    
    },
    async deleteAction(data) {
      let valResp = await QueryService.validateDeleteQuery(data.id, this.$store);
      console.log({valResp})
      if(!valResp.data.canDelete){
        this.$swal({
          title: 'Error',
          text: valResp.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false
        })
      }else{
        this.$swal({
          title: '¿Desea eliminar esta consulta?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.deleteData(data.name)
          }
        })
      }
    },
    async deleteData(name) {
      this.names_restricciones = this.names_restricciones.filter((e) => e.name !== name)
    },
    resetFormConsult() {
      this.isEdit = false
      this.name = ''
      this.items = {
        project_id : JSON.parse(localStorage.getItem('project_id')), 
        owner_id: null,
        status: null,
        priority: 'todas',
        status_restriction:'todas',
        type: 'Todos',
        critic_route: false,
        status_similar:'Todos',
        time: '',
        category_id: 'all',
        speciality_id: 'all',
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null
      }
    },
    async saveConsult() {
      this.showLoading = true
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
        
          let dataSend = {
            name: this.name,
            project_id: Number(this.items.project_id),
            by_cowner: false,
            filter: []
          }
          dataSend = this.construcArray(dataSend, this.items)
          console.log({dataSend})
          let resp = ''
          if (this.isEdit) {
            resp = await QueryService.updateQuery(this.id_consult, dataSend, this.$store)
          } else {
            resp = await QueryService.saveQuery(dataSend, this.$store)
          }
        
          if (resp.status) {
            this.$swal({
              title: this.isEdit ? 'Actualizado' : 'Registrado',
              text: 'La consulta ha sido' + (this.isEdit ? ' actualizada.' : ' registrada.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.$refs['modal-consult'].hide()
            this.resetFormConsult()
            this.getConsultas()
          } else {
            this.$swal({
              title: 'Error!',
              text: 'Ocurrió un error al' + (this.isEdit ? ' actualizar' : ' registrar') + ' los datos de la consulta.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        }
      })
      this.showLoading = false
    },
    construcArray(data, item) {
      
      if (item.project_id) {
        data.filter.push({
          keyContains: 'project_id',
          key: 'equals',
          value: item.project_id
        })
      }
      if (item.status_restriction  && item.status_restriction != 'todas') {
        data.filter.push({
          keyContains: 'status_restriction',
          key: 'equals',
          value: item.status_restriction
        })
      }
      if (item.status_similar  && item.status_similar != 'Todos') {
        data.filter.push({
          keyContains: 'status_similar',
          key: 'equals',
          value: item.status_similar
        })
      }
      if (item.type  && item.type != 'Todos') {
        data.filter.push({
          keyContains: 'type',
          key: 'equals',
          value: item.type
        })
      }
      data.filter.push({
        keyContains: 'critic_route',
        key: 'equals',
        value: item.critic_route
      })
      if (item.owner_id && !item.owner_id.includes('all')) {
        if (item.owner_id.length > 0) {
          data.filter.push({
            keyContains: 'owner_id',
            key: 'in',
            value: JSON.stringify(item.owner_id)
          })
        }
      }
     
      if (item.status) {
        if (item.status == 'proximos-a-vencer') {
          data.filter.push({
            keyContains: 'status',
            key: 'equals',
            value: item.status,
            time: item.time
          })
        } else {
          data.filter.push({
            keyContains: 'status',
            key: 'equals',
            value: item.status
          })
        }
      }
      if (item.priority && item.priority != 'todas') {
        data.filter.push({
          keyContains: 'priority',
          key: 'equals',
          value: item.priority
        })
      }
      // if (item.time != '') {
      //   data.filter.push({
      //     keyContains: 'time',
      //     key: 'equals',
      //     value: item.time
      //   })
      // }
      if (item.category_id && !item.category_id.includes('all')) {
        if (item.category_id.length > 0) {
          data.filter.push({
            keyContains: 'category_id',
            key: 'in',
            value: JSON.stringify(item.category_id)
          })
        }
      } 
      if (item.speciality_id && !item.speciality_id.includes('all')) {
        if (item.speciality_id.length > 0) {
          data.filter.push({
            keyContains: 'speciality_id',
            key: 'in',
            value: JSON.stringify(item.speciality_id)
          })
        }
      }      
      if (item.location_1_id) {
        data.filter.push({
          keyContains: 'location_1_id',
          key: 'equals',
          value: item.location_1_id
        })
      }
      if (item.location_2_id) {
        data.filter.push({
          keyContains: 'location_2_id',
          key: 'equals',
          value: item.location_2_id
        })
      }
      if (item.location_3_id) {
        data.filter.push({
          keyContains: 'location_3_id',
          key: 'equals',
          value: item.location_3_id
        })
      }
      if (item.location_4_id) {
        data.filter.push({
          keyContains: 'location_4_id',
          key: 'equals',
          value: item.location_4_id
        })
      }
      data.filter = JSON.stringify(data.filter)
      return data
    },
    addConsulta() {
      this.$refs['modal-consult'].show()
      // this.getSelectModal(this.project_id)
    },
    //LIBERAR Y RECHAZAR
    //VALIDAR FECHA
    validDate(fecha) {
  
      if (fecha != null) {
        const year = new Date(fecha).getFullYear()
        if (year <= 1970) {
          return ''
        }
        return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
      }
      return ''
    },
    showFilters() {
      this.isAdd = true
    },
    async selectProject(id) {
      // this.project_id = id
      this.$refs.refFormObserver.reset()
      this.items.owner_id = null
      this.items.category_id = null
      this.items.speciality_id = null
      this.items.location_1_id = null
      this.items.location_2_id = null
      this.items.location_3_id = null
      this.items.location_4_id = null
      this.local1 = []
      this.local2 = []
      this.local3 = []
      this.local4 = []
      let url1 = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respLocal1 = await LocalizationService.getLocations1(url1, this.$store)
    
      if (respLocal1.status) {
        this.local1 = respLocal1.data.rows
      }
      this.getSelectModal(id)
    },
    async getConsultas() {
      this.showLoading = true
      //  +  JSON.stringify([{ keyContains: 'area.project_id', key: 'equals', value: id }])
      const url =
        `?limit=10000&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
        JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: this.project_id }])
      const resp = await QueryService.getQuerys(url, this.$store)
      console.log({resp})
      if (resp.status) {
        this.records = resp.data.rows
        if (resp.data.rows.length > 0) {
          const arrayRecord = resp.data.rows
          for (let index = 0; index < arrayRecord.length; index++) {
            const element = arrayRecord[index]
            element.filter = this.destructArray(element.filter)
          }
          if (resp.data.rows.length > 0) {
            this.records = arrayRecord
           
            this.id_query_restric = this.records[0].id
            this.getData()
            this.totalElements = resp.data.responseFilter.total_rows
          }
        }
      }
     
      this.showLoading = false
    },
    destructArray(objeto) {
     
      const obj = {}
      const filters = Array.isArray(objeto) ? objeto : JSON.parse(objeto)
     
      filters.forEach((element1, index) => {
        if (element1.keyContains != 'time') {
          if (element1.keyContains == 'status') {
            if (element1.value == 'proximos-a-vencer') {
              obj[`time`] = element1.time
            }
            obj[`status`] = element1.value
          } else {
           
            obj[`${element1.keyContains}`] = element1.keyContains == 'owner_id' ||  element1.keyContains == 'category_id' ||  element1.keyContains == 'speciality_id'  ? element1.value ? JSON.parse(element1.value) : '' : element1.value
          }
        }
      })
      objeto = obj     
     
      return objeto
    },
    edit(item) {
      this.isEdit = true
      this.name = item.name
      this.id_consult = item.id
      console.log("item", item)
      // this.project_id = item.project_id
     
      this.$refs['modal-consult'].show()
      if (item.filter.location_1_id != null) {
        this.changeLocal(item.filter.location_1_id, 1)
      }
      if (item.filter.location_2_id != null) {
        this.changeLocal(item.filter.location_2_id, 2)
      }
      if (item.filter.location_3_id != null) {
        this.changeLocal(item.filter.location_3_id, 3)
      }
      this.items = item.filter
      console.log("item.filter", item.filter)
      this.items.project_id = item.filter.project_id ? item.filter.project_id : item.project_id
      this.getSelectModal(this.items.project_id)
      this.items.priority = item.filter.priority ? item.filter.priority : 'todas'
      this.items.status_restriction =  item.filter.status_restriction ? item.filter.status_restriction : 'todas'
      this.items.status_similar =  item.filter.status_similar ? item.filter.status_similar : 'Todos'
      this.items.type =  item.filter.type ? item.filter.type : 'Todos'
      this.items.critic_route =  item.filter.critic_route
      if (!this.items.hasOwnProperty('owner_id')) {
        console.log("NO TIENE OWNER ID, ASÍ QUE ES TODOS");
        this.items.owner_id = ['all'];
      }
      if (!this.items.hasOwnProperty('category_id')) {
        console.log("NO TIENE CATEGORY ID, ASÍ QUE ES TODOS");
        this.items.category_id = ['all'];
      }
      if (!this.items.hasOwnProperty('speciality_id')) {
        console.log("NO TIENE SPECIALITY ID, ASÍ QUE ES TODOS");
        this.items.speciality_id = ['all'];
      }
      console.log("ITEM STEADO", this.items)
    },
    async deleteAction(data) {
      let valResp = await QueryService.validateDeleteQuery(data.id, this.$store);
      console.log({valResp})
      if(!valResp.data.canDelete){
        this.$swal({
          title: 'Error',
          text: valResp.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false
        })
      }else{

        this.$swal({
          title: '¿Desea eliminar esta consulta?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.deleteData(data.id)
          }
        })
      }
    },
    async deleteData(id) {
     
      const resp = await QueryService.deleteQuery(id, this.$store)
      if (resp.status) {
        this.currentPage = 1
        this.$swal({
          icon: 'success',
          title: 'Eliminado!',
          text: 'La consulta ha sido eliminado.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
        this.getConsultas()
      } else {
        this.$swal({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar la consulta seleccionada.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    },
    async getSelectModal(id) {
      // const url = `?limit=100`
      const url = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'area.project_id', key: 'equals', value: id }])
      const respOwner = await OwnerService.getOwners(url, this.$store)
      if (respOwner.status) {
        this.duenos = respOwner.data.rows
      }
      const url1 = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respCategorys = await CategoryService.getCategorys(url1, this.$store)
      if (respCategorys.status) {
        this.categorias = respCategorys.data.rows
      }

      const url2 = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respEspecialitys = await SpecialityService.getSpecialitys(url2, this.$store)
      if (respEspecialitys.status) {
        this.especialidades = respEspecialitys.data.rows
      }
      const url3 = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respLocation = await LocalizationService.getLocations1(url3, this.$store)
      if (respLocation.status) {
        this.local1 = respLocation.data.rows
      }
    },
    async changeLocal(id, level) {
      // this.$refs.refFormObserver.reset()
      if (level == 1) {
        this.items.location_2_id = null
        this.items.location_3_id = null
        this.items.location_4_id = null
        this.local2 = []
        this.local3 = []
        this.local4 = []

        let url = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'location_1_id', key: 'equals', value: id }])
        const respLocal2 = await LocalizationService.getLocations2(url, this.$store)
        if (respLocal2.status) {
          this.local2 = respLocal2.data.rows
        }
      } else if (level == 2) {
        this.items.location_3_id = null
        this.items.location_4_id = null
        this.local3 = []
        this.local4 = []
        let url = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'location_2_id', key: 'equals', value: id }])
        const respLocal3 = await LocalizationService.getLocations3(url, this.$store)
        if (respLocal3.status) {
          this.local3 = respLocal3.data.rows
        }
      } else {
        this.items.location_4_id = null
        this.local4 = []
        let url = `?limit=10000&filter=` + JSON.stringify([{ keyContains: 'location_3_id', key: 'equals', value: id }])
        const respLocal4 = await LocalizationService.getLocations4(url, this.$store)
        if (respLocal4.status) {
          this.local4 = respLocal4.data.rows
        }
      }
    },
    showFilters() {
      this.isAdd = true
    },
    async getSelect() {
      const url2 = `?limit=10000`
      const respProyectos = await ProjectsService.getProyectos(url2, this.$store)
     
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
      }
    },
    async getData() {
      const url =
        this.id_query_restric +
        `?limit=${this.showEntrie2}&page=${this.currentPage2}&order=${this.order2}&sort=${this.sort2}`
      this.showLoading = true
      console.log({urlGedata:url})
      const respConsult = await QueryService.getQueryRestriction(url, this.$store)
     
      if (respConsult.status) {
       
        this.restricciones = respConsult.data.rows
        this.totalElements2 = respConsult.data.responseFilter.total_rows
      }
      this.showLoading = false
    },
    //CONSULTAS
    cambioPagina(e) {
      this.currentPage = e
      this.getConsultas()
    },
    changeSizePage() {
      this.getConsultas()
    },
    sortChanged(data) {
      this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc'
    },
    sortChanged2(data) {
      this.sort2 = data.sortBy
      if (data.sortDesc) {
        this.order2 = 'desc'
      } else this.order2 = 'asc'
    },
    //restricciones
    cambioPagina2(e) {
     
      this.currentPage2 = e
      this.getData()
    },
    changeSizePage2() {
      this.getData()
    },
    async open(item){
      console.log(item)
      const url =
      item.id +
        `?limit=10000&page=${this.currentPage2}&order=${this.order2}&sort=${this.sort2}`
      this.showLoading = true
      console.log({urlGedata:url})
      const respConsult = await QueryService.getQueryRestriction(url, this.$store)
     console.log("resp query", respConsult)
      if (respConsult.status) {    
        this.dataExcel =  respConsult.data.rows
        this.modalOpen = true;

      }
      this.showLoading = false
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.pad-export {
  padding: 0.52rem 1rem !important;
}
.content_main {
  .content_timeline {
    max-height: 450px;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  .content_form {
    display: flex;
    justify-content: center;
  }
}
.select-obra .vs__dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}

.fullInput .vs__dropdown-toggle {
  overflow: hidden !important;
  flex-flow: nowrap !important;
  text-wrap: nowrap !important;
  height: auto !important;
}
.mt-02 {
  margin-top: 0.2rem;
}
</style>
