<template>
    <div>
      <b-table :items="datos" :fields="fields" responsive no-header striped sticky-header="95vh">      
        <template #cell(date_identified)="data">
            <span>{{ validDate(data.item.date_identified) }}</span>
        </template>
        <template #cell(date_required)="data">
            <span>{{ validDate(data.item.date_required) }} </span>
        </template>
        <template #cell(date_commited)="data">
            <span>{{ validDate(data.item.date_commited) }}</span>
        </template>
        <template #cell(critic_route)="data">
          <div class="date-liberado-class">
            <span v-if="data.item.critic_route">Sí</span>
            <span v-else>No</span>
          </div>
        </template>
      </b-table>
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  import 'moment-timezone';
  
  export default {
    props: {
      datos: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        firstRow: [],
        info:[],
        fields: [
        { key: 'description', label: 'Descripción', sortable: false },
        { key: 'category.description', label: 'Categoria', sortable: false },      
        { key: 'priority', label: 'Prioridad', sortable: false },
        { key: 'status_similar', label: 'Estado', sortable: false },
        { key: 'owner.name', label: 'Dueño', sortable: false },        
        { key: 'date_identified', label: 'F. Identificada', sortable: false },
        { key: 'date_required', label: 'F. Requerida', sortable: false },
        { key: 'date_commited', label: 'F. Comprometida', sortable: false },    
        { key: 'critic_route', label: '¿Es Ruta Crítica?', sortable: false }    
        ],
      };
    },
    created() {
    },
    mounted(){    
     
    },
    methods: {
      // Función para formatear una fecha
      validDate(fecha) {
  
        if (fecha != null) {
            const year = new Date(fecha).getFullYear()
            if (year <= 1970) {
            return ''
            }
            return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
        }
        return ''
        },
    },
  };
  </script>
  
  <style lang="scss">
    .date-class {
      width: 100px;
    }
  </style>
  